import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import "./hero.scss"

type Props = {
  title: string
  subtitle: string
  subtitleSecondLine?: string
  bgImageAlt: string
}

const Hero = (props: Props) => {
  return (
    <div className="hero">
      <StaticImage
        className="image"
        src="../../images/hero-index.jpg"
        alt={props.bgImageAlt}
      />
      <h1>{props.title}</h1>
      <h3>{props.subtitle}</h3>
      {props.subtitleSecondLine && <h3>{props.subtitleSecondLine}</h3>}
    </div>
  )
}

export default Hero
