import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import "./jobs.scss"

const Jobs = () => {
  return (
    <div className="jobs-container">
      <h2 className="title">
        {" "}
        <StaticImage
          alt="Resume logo"
          src="../../images/resume.svg"
        /> Resume{" "}
      </h2>

      <div className="timeline">
        <div className="job">
          <div className="left">
            <h6>Aug 2021 - Now</h6>
            <h3>Fullstack developer</h3>
            <p>Vest</p>
          </div>
          <div className="right">
            I am part of the development of the team. Work with React Native,
            Gatsby, FastAPI, Typescript, PostgreSQL, and Tailwind.
          </div>
        </div>
        <div className="job">
          <div className="left">
            <h6>May 2018 - July 2021</h6>
            <h3>ML Engineer</h3>
            <p>NDS Cognitive Labs</p>
          </div>
          <div className="right">
            I was part of the development team of the solutions in the company.
            Primary develop with Python, React, Angular, Flutter with deploy in
            GCP and AWS.
          </div>
        </div>
        <div className="job">
          <div className="left">
            <h6>Aug 2016 - March 2018</h6>
            <h3>Frontend Developer</h3>
            <p>National Center of Calculus (CENAC)</p>
          </div>
          <div className="right">
            I was in the frontend team of Plataforma IPN.
          </div>
        </div>
        <div className="job">
          <div className="left">
            <h6>Dec 2015 - Jul 2016</h6>
            <h3>Social Service</h3>
            <p>National Center of Calculus (CENAC)</p>
          </div>
          <div className="right">
            I helped the CENAC’s team construct of their apps/systems
          </div>
        </div>
      </div>
    </div>
  )
}

export default Jobs
