import { string } from "prop-types"
import React from "react"

import "./certification.scss"

type Props = {
  name: string
  date: string
  url: string
}

const CertificationCard = ({ name, date, url }: Props) => {
  function goToPage() {
    const win = window.open(url, "_blank")
    win.focus()
  }

  return (
    <div onClick={goToPage} className="certificationCard">
      <h4>{date}</h4>
      <h3>{name}</h3>
      <p>
        <a target="blank" rel="noopener noreferrer" href={url}>
          See certificate
        </a>
      </p>
    </div>
  )
}

export default CertificationCard
