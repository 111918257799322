import * as React from "react"
import Certifications from "../components/Certification/Certification"
import Hero from "../components/Hero/Hero"
import Jobs from "../components/Jobs/Jobs"
import Layout from "../components/Layout/Layout"
import Projects from "../components/Projects/Projects"
import SEO from "../components/SEO/SEO"

import "./index.scss"

const IndexPage = () => (
  <Layout>
    <SEO
      description="Showcase website of the machine learning engineer Ricardo Colin."
      title="Ricardo Colin - ML Engineer"
    />
    <div className="home-content">
      <Hero
        title="Ricardo Colin"
        subtitleSecondLine="&#8220;There is nothing more powerful than an idea whose time has come&#8221; - Victor Hugo"
        subtitle="ML Engineer | Data Engineer"
        bgImageAlt="Hero background"
      />
      <Projects full={false} />
      <Jobs />
      <Certifications />
    </div>
  </Layout>
)

export default IndexPage
