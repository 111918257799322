import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./certification.scss"
import CertificationCard from "./CertificationCard"

const Certifications = () => {
  const certifications: Array<{ date: string; name: string; url: string }> = [
    {
      date: "May 2022",
      name: "Google Data Analytics",
      url: "/data-analysis-certification.pdf",
    },
    {
      date: "March 2021",
      name: "Tensorflow Developer Certificate",
      url: "/tensorflow-certificate.pdf",
    },
    {
      date: "Jan 2021",
      name: "DeepLearning.AI TensorFlow Developer",
      url: "/deeplearning-ai-tensorflow-certificate.pdf",
    },
    {
      date: "Jul 2020",
      name: "Neo4j Professional",
      url: "/neo4j.pdf",
    },
    {
      date: "Feb 2018",
      name: "Scrum product owner certified",
      url: "/scrum-product-owner.pdf",
    },
    {
      date: "Feb 2018",
      name: "Scrum fundamentals certified",
      url: "/scrum-fundamentals.pdf",
    },
  ]

  return (
    <div className="certificationsContainer">
      <h2 className="title">
        {" "}
        <StaticImage
          src="../../images/certifications.svg"
          alt="certifications"
        />{" "}
        Certifications{" "}
      </h2>
      <div className="list">
        {certifications.map(c => {
          return <CertificationCard name={c.name} date={c.date} url={c.url} />
        })}
      </div>
    </div>
  )
}

export default Certifications
